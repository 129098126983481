import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../AuthContext';
import './ListBots.css'; // Assuming the styles are in this file
import API_URLS from '../../config/apiUrls';

function ListBots() {
  const [bots, setBots] = useState([]);
  const { auth } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState(''); // Add this line

  useEffect(() => {
    const fetchBots = async () => {
      try {
        const response = await axios.get(
          API_URLS.LIST_BOTS,
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );
        setBots(response.data.bots);
      } catch (error) {
        console.error(error);
      }
    };

    fetchBots();
  }, [auth.token]);

  return (
    <div className="ListBots-container">
      <h3>Running Bots</h3>
      <input
        type="text"
        className="search-bar"
        placeholder="Search by Market Pair..."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)} // Update search term
      />
      <table>
        <thead>
          <tr>
            <th>Bot ID</th>
            <th>Type</th>
            <th>Symbol</th>
            <th>Asset</th>
            <th>Invested</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {/* To do:
            - convert commas (,) into dots (.) when it comes to decimal numbers.
          */}
          {bots
            .filter(bot => bot.status !== "Closed" && bot.symbol.toUpperCase().includes(searchTerm.toUpperCase()))
            .map(bot => (
              <tr key={bot._id}>
                <td>{bot.botID}</td>
                <td>{bot.type}</td>
                <td>{bot.symbol}</td>
                <td>{bot.initialAsset}</td>
                <td>{bot.initialQuantity}</td>
                <td>{bot.status}</td>
              </tr>
            ))}
        </tbody>

      </table>
    </div>
  );
}

export default ListBots;
