import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../AuthContext';
import './UpdateExchange.css';
import API_URLS from '../../config/apiUrls';

function UpdateExchange() {
  const [selectedExchange, setSelectedExchange] = useState(null);
  const [availableExchanges, setAvailableExchanges] = useState([]);
  
  const [credentials, setCredentials] = useState({
    binance: { key: '', secret: '' },
    kucoin: { key: '', secret: '', password: '' },
    cexio: { key: '', secret: '' },
    okx: { key: '', secret: '' }
  });
  
  const [errorMessage, setErrorMessage] = useState('');
  const { auth, setExchange } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExchanges = async () => {
      try {
        const response = await axios.get(API_URLS.EXCHANGES_AVAILABILITY, {
          headers: { Authorization: `Bearer ${auth.token}` }
        });
  
        // Ensure response.data.exchanges is an array
        setAvailableExchanges(Array.isArray(response.data.exchanges) ? response.data.exchanges : []);
      } catch (error) {
        console.error('Error fetching exchange availability:', error);
        setAvailableExchanges([]); // Ensure state remains an array
      }
    };
    fetchExchanges();
  }, [auth.token]);
  

  const handleChange = (exchange, field, value) => {
    setCredentials(prev => ({
      ...prev,
      [exchange]: {
        ...prev[exchange],
        [field]: value
      }
    }));
  };

  const handleSubmit = async (exchange) => {
    try {
      const { key, secret, password } = credentials[exchange];
      const data = { exchange, key, secret };
      if (exchange === 'kucoin') data.password = password;
  
      await axios.post(API_URLS.UPDATE_EXCHANGE, data, {
        headers: { Authorization: `Bearer ${auth.token}` }
      });
  
      alert(`${exchange.toUpperCase()} API keys updated successfully`);
  
      // ✅ Ensure exchange is saved properly and update the state immediately
      setAvailableExchanges((prev) => {
        const updatedExchanges = [...new Set([...prev, exchange])];
        sessionStorage.setItem('availableExchanges', JSON.stringify(updatedExchanges));
        return updatedExchanges;
      });
  
      setExchange(exchange); // ✅ Set the selected exchange immediately
      localStorage.setItem('exchange', exchange); // ✅ Save to localStorage
  
      // ✅ Navigate to dashboard after ensuring state update
      setTimeout(() => {
        navigate('/dashboard', { replace: true });
      }, 100);
      
      setSelectedExchange(null);
    } catch (error) {
      console.error(`${exchange.toUpperCase()} update error:`, error);
      setErrorMessage(`Error updating ${exchange.toUpperCase()} details`);
    }
  };
  
  
  
  
  
  

  const toggleExchange = (exchange) => {
    setSelectedExchange(prev => (prev === exchange ? null : exchange));
  };

  const exchanges = [
    { name: 'binance', label: 'Binance' },
    { name: 'kucoin', label: 'KuCoin' },
    { name: 'cexio', label: 'CEX.io' },
    { name: 'okx', label: 'OKX' }
  ];

  return (
    <div className="UpdateExchange">
      <h3>Update API Keys</h3>
      {errorMessage && <p className="error">{errorMessage}</p>}
      
      {/* Connected Exchanges Row */}
      {availableExchanges.length > 0 && (
        <div className="connected-exchange-row">
          {availableExchanges.map((exchange) => (
            <div className="exchange-card connected" key={exchange}>
              <h4>{exchange.toUpperCase()}</h4>
              <span className="status-light green"></span>
              <button className="manage-btn" onClick={() => { toggleExchange(exchange); setExchange(exchange); navigate('/dashboard'); }}>Manage</button>
            </div>
          ))}
        </div>
      )}

      {/* Available Exchanges */}
      <div className="exchange-row">
        {exchanges.filter(({ name }) => !availableExchanges.includes(name)).map(({ name, label }) => (
          <div className={`exchange-card ${selectedExchange === name ? 'active' : ''}`} key={name}>
            <div className="exchange-header">
              <h4>{label}</h4>
              <button className="add-btn" onClick={() => toggleExchange(name)}>
                {selectedExchange === name ? 'Close' : 'Add'}
              </button>
            </div>

            {selectedExchange === name && (
              <div className="exchange-form">
                <label>{label} API Key:</label>
                <input
                  type="text"
                  value={credentials[name].key}
                  onChange={(e) => handleChange(name, 'key', e.target.value)}
                />
                <label>{label} Secret:</label>
                <input
                  type="text"
                  value={credentials[name].secret}
                  onChange={(e) => handleChange(name, 'secret', e.target.value)}
                />
                {name === 'kucoin' && (
                  <>
                    <label>KuCoin Passphrase:</label>
                    <input
                      type="password"
                      value={credentials.kucoin.password}
                      onChange={(e) => handleChange('kucoin', 'password', e.target.value)}
                    />
                  </>
                )}
                <button className="submit-btn" onClick={() => handleSubmit(name)}>Save</button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default UpdateExchange;