import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { getKucoinExchangeInfo } from '../../utils/exchangeAPI';
import { useNavigate, Link } from 'react-router-dom';
import './TopMovers.css';

function TopMovers() {
    const { setRawPair, rawPair, setPair } = useContext(AuthContext);
    const [exchangeInfo, setExchangeInfo] = useState([]);
    const [filteredInfo, setFilteredInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('top-gainers');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchKucoinInfo = async () => {
            setLoading(true);
            try {
                let data = await getKucoinExchangeInfo();
                data = data.filter(item => parseFloat(item.last) > 0);
                setExchangeInfo(data);
                setFilter('Top-Gainers'); // Ensure the first filter is selected
                applyFilter(data, 'Top-Gainers'); // Apply filter immediately
            } catch (error) {
                console.error('Error fetching KuCoin exchange info:', error);
            }
            setLoading(false);
        };
        fetchKucoinInfo();
    }, []);
    

    useEffect(() => {
        applyFilter(exchangeInfo, filter);
    }, [filter, exchangeInfo]);

    useEffect(() => {
        setPair(rawPair.replace(/[-/]/g, ''));
    }, [rawPair, setPair]);

    const applyFilter = (data, selectedFilter) => {
        let sortedData = [...data];
        switch (selectedFilter) {
            case 'Top-Gainers':
                sortedData = sortedData.filter(item => parseFloat((item.changePrice / (item.last - item.changePrice)) * 100) > 0);
                sortedData.sort((a, b) => parseFloat(b.changePrice) / (b.last - b.changePrice) - parseFloat(a.changePrice) / (a.last - a.changePrice));
                break;
            case 'Top-Losers':
                sortedData = sortedData.filter(item => parseFloat((item.changePrice / (item.last - item.changePrice)) * 100) < 0);
                sortedData.sort((a, b) => parseFloat(a.changePrice) / (a.last - a.changePrice) - parseFloat(b.changePrice) / (b.last - b.changePrice));
                break;
            case 'High-Volume':
                sortedData.sort((a, b) => parseFloat(b.volValue) - parseFloat(a.volValue));
                break;
            case 'Most-Traded':
                sortedData.sort((a, b) => parseFloat(b.last) * parseFloat(b.volValue) - parseFloat(a.last) * parseFloat(a.volValue));
                break;
            default:
                break;
        }
        setFilteredInfo(sortedData.slice(0, 10));
    };

    const handleCreateBotClick = (symbol) => {
        setRawPair(symbol);
        setPair(symbol.replace(/[-/]/g, ''));
        navigate('/create-bot', { replace: false });
        window.scrollTo(0, 0);
    };

    return (
        <div className='Info'>
            <h3>Top 10 {filter.replace('-', ' ')} on KuCoin</h3>
            
            {/* Filter Buttons */}
            <div className="filter-buttons">
                {['Top-Gainers', 'Top-Losers', 'High-Volume', 'Most-Traded'].map((type) => (
                    <button 
                        key={type} 
                        className={`filter-btn ${filter === type ? 'active' : ''}`} 
                        onClick={() => setFilter(type)}
                    >
                        {type.replace('-', ' ')}
                    </button>
                ))}
            </div>

            {loading ? (
                <div className="spinner"></div>
            ) : (
                <>
                    <table>
                        <thead>
                            <tr>
                                <th>Symbol</th>
                                <th>Price</th>
                                <th>Volume 24h</th>
                                <th>High 24h</th>
                                <th>Low 24h</th>
                                <th>Change 24h</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredInfo.map((item) => (
                                <tr key={item.symbol}>
                                    <td>{item.symbol}</td>
                                    <td>{parseFloat(item.last).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 8 })}</td>
                                    <td>{parseFloat(item.volValue).toLocaleString('en-US', { minimumFractionDigits: 2 })}</td>
                                    <td>{parseFloat(item.high).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 8 })}</td>
                                    <td>{parseFloat(item.low).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 8 })}</td>
                                    <td style={{ color: parseFloat(item.changePrice) < 0 ? '#D9534F' : '#28A745' }}>
                                        {((parseFloat(item.changePrice) / (parseFloat(item.last) - parseFloat(item.changePrice))) * 100)
                                            .toLocaleString('en-US', { minimumFractionDigits: 2 })}%
                                    </td>

                                    <td>
                                        <button className="create-bot-btn" onClick={() => handleCreateBotClick(item.symbol)}>
                                            <i className="fa fa-link"></i> Trade
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="link-container">
                        <Link className="link-markets" to="/markets">Show All Markets</Link>
                    </div>
                </>
            )}
        </div>
    );
}

export default TopMovers;
