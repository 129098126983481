import React, { useState, useContext } from 'react';
import './Login.css';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import logo from '../../images/logo.png';
import API_URLS from '../../config/apiUrls';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [twoFAToken, setTwoFAToken] = useState('');
  const [showTwoFAModal, setShowTwoFAModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [twoFALoading, setTwoFALoading] = useState(false);
  const { setAvailableExchanges } = useContext(AuthContext);

  const { setAuthToken, setApiKey, setTradingPairs, setCex } = useContext(AuthContext);
  const navigate = useNavigate();

  const loginUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post(API_URLS.LOGIN, { email, password });
      if (res.data.user?.twoFactor?.enabled) {
        setShowTwoFAModal(true);
        setLoading(false);
      } else {
        completeLogin(res.data);
      }
    } catch (error) {
      setLoading(false);
      alert(error.response?.data?.error || 'Login failed');
    }
  };

  const verifyTwoFactor = async () => {
    setTwoFALoading(true);
    try {
      const res = await axios.post(API_URLS.TFA_VERIFY_LOGIN, {
        email,
        password,
        token: twoFAToken,
      });
      completeLogin(res.data);
    } catch (error) {
      setTwoFALoading(false);
      alert(error.response?.data?.error || '2FA verification failed');
    }
  };

  const completeLogin = async (data) => {
    localStorage.setItem('token', data.token);
    setAuthToken(data.token);
    setApiKey(data.apiKey);
    setCex(Boolean(data.user?.binance?.key || data.user?.kucoin?.key));
  
    try {
      // ✅ Fetch exchange availability
      const availabilityRes = await axios.get(API_URLS.EXCHANGES_AVAILABILITY, {
        headers: { Authorization: `Bearer ${data.token}` },
      });
  
      const availableExchanges = availabilityRes.data.exchanges;
  
      // ✅ Store exchanges & update state
      sessionStorage.setItem('availableExchanges', JSON.stringify(availableExchanges));
      setAvailableExchanges(availableExchanges);
  
      // ✅ Fetch Trading Pairs
      const tradingPairsRes = await axios.get(API_URLS.TRADING_PAIRS, {
        headers: { Authorization: `Bearer ${data.token}` },
      });
  
      const tradingPairs = tradingPairsRes.data;
  
      // ✅ Store Trading Pairs in Context and Local Storage
      setTradingPairs(tradingPairs);
      localStorage.setItem('tradingPairs', JSON.stringify(tradingPairs));
  
      // ✅ Redirect based on exchange availability
      if (availableExchanges.length === 0 || availableExchanges === 'noExchanges') {
        navigate('/update-exchange');
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Failed to fetch required data:', error.response?.data?.error || error.message);
      sessionStorage.setItem('availableExchanges', JSON.stringify('noExchanges'));
      setAvailableExchanges([]);
      navigate('/update-exchange');
    }
  };
  
  
  
  

  return (
    <div>
      {twoFALoading ? (
        <div className="twofa-spinner-container">
          <img src={logo} alt="Verifying..." className="rotating-logo" />
          <p>Verifying 2FA...</p>
        </div>
      ) : showTwoFAModal ? (
        <div className="twofa-modal">
          <h3>Two-Factor Authentication</h3>
          <input className="login-input" type="text" placeholder="2FA Code" value={twoFAToken} onChange={(e) => setTwoFAToken(e.target.value)} required />
          <button className="login-button" onClick={verifyTwoFactor}>Verify</button>
          {/* <button onClick={() => setShowTwoFAModal(false)}>Cancel</button> */}
        </div>
      ) : (
        <form className="login-form" onSubmit={loginUser}>
          <h2>Login</h2>
          <input className="login-input" type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          <input className="login-input" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
          <button className="login-button" type="submit">Login</button>
          <Link to="/forgot-password">Forgot Password?</Link>
        </form>
      )}
    </div>
  );
}

export default Login;

