import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { getBinanceExchangeInfo, getKucoinExchangeInfo } from '../../utils/exchangeAPI';
import { useNavigate } from 'react-router-dom';
import './ExchangeInfo.css'; // Assuming you want the same styles


function ExchangeInfo() {
    const { exchange, setRawPair, rawPair, setPair } = useContext(AuthContext); // Destructure setRawPair from context
    const [exchangeInfo, setExchangeInfo] = useState(null);
    const [filteredExchangeInfo, setFilteredExchangeInfo] = useState([]);
    const [sortConfig, setSortConfig] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate(); // Initialize useHistory

    const handleCreateBotClick = (symbol) => {
      setRawPair(symbol); // Set the rawPair to the symbol from the column
      // Ensure that setPair is updated before navigating
      setPair(symbol.replace(/[-/]/g, ''));
      navigate('/create-bot', {replace: false}); // Navigate to /create-bot
      window.scrollTo(0, 0);
    };
    

    const requestSort = (key) => {
      let direction = 'ascending';
      if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };

    useEffect(() => {
      const fetchExchangeInfo = async () => {
        setLoading(true); // Set loading to true at the start
        try {
          let data;
          if (exchange === 'binance') {
            data = await getBinanceExchangeInfo();
            data = data.filter(item => parseFloat(item.lastPrice) > 0); // Filter data
            setExchangeInfo(data);
          } else if (exchange === 'kucoin') {
            data = await getKucoinExchangeInfo();
            data = data.filter(item => parseFloat(item.last) > 0); // Filter data
            setExchangeInfo(data);
          }
        } catch (error) {
          console.error('Error fetching exchange info:', error);
        }
        setLoading(false); // Set loading to false once data is fetched
      };      
      fetchExchangeInfo();
    }, [exchange]);

    useEffect(() => {
      if (sortConfig !== null && Array.isArray(exchangeInfo)) {
          const sortedData = [...exchangeInfo].sort((a, b) => {
              let keyA, keyB;
              if (exchange === 'binance') {
                  keyA = parseFloat(a[sortConfig.key]);
                  keyB = parseFloat(b[sortConfig.key]);
              } else if (exchange === 'kucoin') {
                  if (['lastPrice', 'high', 'low'].includes(sortConfig.key)) {
                      keyA = parseFloat(a[sortConfig.key.replace('Price', '')]);
                      keyB = parseFloat(b[sortConfig.key.replace('Price', '')]);
                  } else if (sortConfig.key === 'quoteVolume') {
                      keyA = parseFloat(a.volValue);
                      keyB = parseFloat(b.volValue);
                  } else if (sortConfig.key === 'priceChangePercent') {
                      keyA = parseFloat((a.changePrice / (a.last - a.changePrice)) * 100);
                      keyB = parseFloat((b.changePrice / (b.last - b.changePrice)) * 100);
                  } else {
                      keyA = parseFloat(a[sortConfig.key]);
                      keyB = parseFloat(b[sortConfig.key]);
                  }
              }
  
              if (keyA < keyB) {
                  return sortConfig.direction === 'ascending' ? -1 : 1;
              }
              if (keyA > keyB) {
                  return sortConfig.direction === 'ascending' ? 1 : -1;
              }
              return 0;
          });
          setFilteredExchangeInfo(sortedData);
      } else {
          setFilteredExchangeInfo(exchangeInfo);
      }
  }, [sortConfig, exchange, exchangeInfo]);

  useEffect(() => {
      // Update pair whenever rawPair changes
      setPair(rawPair.replace(/[-/]/g, ''));
  }, [rawPair, setPair]);

  
    

    return (
      <div className='Info'>
        <h3>Markets of {exchange.charAt(0).toUpperCase() + exchange.slice(1)}</h3>
        <input 
            type="text" 
            placeholder="Search for a symbol..." 
            value={searchTerm} 
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-bar"
        />

        {loading ? (
          <div className="spinner"></div>
        ) : Array.isArray(filteredExchangeInfo) && filteredExchangeInfo.length > 0 ? (
          <table>
            <thead>
            <tr>
                <th onClick={() => requestSort('symbol')}>Symbol <span className="sort-icon">{sortConfig && sortConfig.key === 'symbol' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▲'}</span></th>
                <th onClick={() => requestSort('lastPrice')}>Price <span className="sort-icon">{sortConfig && sortConfig.key === 'lastPrice' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▲'}</span></th>
                <th onClick={() => requestSort('quoteVolume')}>Volume 24h <span className="sort-icon">{sortConfig && sortConfig.key === 'quoteVolume' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▲'}</span></th>
                <th>High 24h</th>
                <th>Low 24h</th>
                {/* <th onClick={() => requestSort('high')}>High 24h<span className="sort-icon">{sortConfig && sortConfig.key === 'high' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▲'}</span></th>
                <th onClick={() => requestSort('low')}>Low 24h<span className="sort-icon">{sortConfig && sortConfig.key === 'low' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▲'}</span></th> */}
                <th onClick={() => requestSort('priceChangePercent')}>Change 24h<span className="sort-icon">{sortConfig && sortConfig.key === 'priceChangePercent' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▲'}</span></th>
                <th>Action</th>
            </tr>
            </thead>
            <tbody>
              {filteredExchangeInfo.filter(item => item.symbol.toLowerCase().includes(searchTerm.toLowerCase())).map((item) => (
                <tr key={item.symbol}>
                    <td>
                       {item.symbol} {/* Add a button for Create Bot */}
                    </td>
                    <td>
                        {exchange === 'binance' 
                            ? parseFloat(item.lastPrice).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 8 })
                            : parseFloat(item.last).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 8 })}
                    </td>
                    <td>
                        {exchange === 'binance' 
                            ? parseFloat(item.quoteVolume).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 8 })
                            : parseFloat(item.volValue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 8 })}
                    </td>
                    <td>{exchange === 'binance' 
                            ? parseFloat(item.highPrice).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 8 })
                            : parseFloat(item.high).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 8 })}
                    </td>
                    <td>{exchange === 'binance' 
                            ? parseFloat(item.lowPrice).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 8 })
                            : parseFloat(item.low).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 8 })}
                    </td>
                    <td style={{
                        color: exchange === 'binance'
                            ? parseFloat(item.priceChangePercent) > 0 
                                ? '#28A745'
                                : parseFloat(item.priceChangePercent) < 0 
                                    ? '#DC3545' 
                                    : '#c4c4c4'
                            : parseFloat((item.changePrice / (item.last - item.changePrice)) * 100).toFixed(8) > 0 
                                ? '#28A745'
                                : parseFloat((item.changePrice / (item.last - item.changePrice)) * 100).toFixed(8) < 0 
                                    ? '#DC3545' 
                                    : '#c4c4c4'
                    }}>
                        {exchange === 'binance' 
                            ? parseFloat(item.priceChangePercent).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 8 }) + '%'
                            : (parseFloat(item.changePrice / (item.last - item.changePrice)) * 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'}
                    </td>
                    <td>
                        <button className="create-bot-btn" onClick={() => handleCreateBotClick(item.symbol)}><i className="fa fa-link"></i> Trade</button> {/* Add a button for Create Bot */}
                    </td>
                </tr>
              ))}
           </tbody>

        </table>
        ) : (
        <div className="spinner"></div>
        )}
      </div>
    );
}

export default ExchangeInfo;
