import { Navigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { AuthContext } from './AuthContext';

export function AuthenticatedRoute({ children }) {
  const { auth } = useContext(AuthContext);
  return auth.token ? children : <Navigate to="/login" />;
}

// ExchangeRoute: Requires Auth + Exchange
export const ExchangeRoute = ({ children }) => {
  const { auth, availableExchanges, exchange, setAvailableExchanges } = useContext(AuthContext);

  useEffect(() => {
    // ✅ Always execute useEffect at the top level
    const storedExchanges = JSON.parse(sessionStorage.getItem('availableExchanges') || '[]');
    if (storedExchanges.length > 0) {
      setAvailableExchanges(storedExchanges);
    }
  }, [setAvailableExchanges]); // ✅ Dependency array only includes `setAvailableExchanges`

  // ✅ No conditional useEffect - Instead, conditionally return <Navigate />
  if (!auth.token) {
    return <Navigate to="/login" />;
  }

  if (!exchange || !availableExchanges || availableExchanges.length === 0 || availableExchanges === 'noExchanges') {
    return <Navigate to="/update-exchange" />;
  }

  return children;
};

// PublicRoute: No restrictions
export const PublicRoute = ({ children }) => {
  return children;
};
