// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import './App.css';
import RedirectToAvailable from './components/RedirectToAvailable';
import { AuthenticatedRoute, ExchangeRoute, PublicRoute } from './components/ProtectedRoute';
import TradingPlatform from './components/tradingPlatform/TradingPlatform';
import JoePesciComponent from './components/joePesci/JoePesciComponent';
import ExchangeInfo from './components/exchangeInfo/ExchangeInfo';
import Register from './components/register/Register';
import Login from './components/login/Login';
import Profile from './components/profile/Profile';
import ForgotPassword from './components/forgotPassword/ForgotPassword';
import ResetPassword from './components/resetPassword/resetPassword';
import CheckYourInbox from './components/register/checkYourInbox';
import ConfirmEmail from './components/confirmEmail/confirmEmail';
import UpdateExchange from './components/updateExchange/UpdateExchange';
import Dash from './components/dash/Dash';
import TwoFactorSetup from './components/2fa/TwoFactorSetup';
import Home from './components/accountApi/AccountAPI';

function App() {
  // State to store total values
  const [totalMain, setTotalMain] = useState(0);
  const [totalTrade, setTotalTrade] = useState(0);
  const [totalFutures, setTotalFutures] = useState(0);

  return (
    <AuthProvider>
      <Router>
        <div className="app-container">
          <Navbar />
          <div className="main-content">
            <Routes>
              {/* PUBLIC ROUTES */}
              <Route path="/" element={<PublicRoute><JoePesciComponent /></PublicRoute>} />
              <Route path="/markets" element={<PublicRoute><ExchangeInfo /></PublicRoute>} />
              <Route path="/create-bot" element={<PublicRoute><TradingPlatform /></PublicRoute>} />
              <Route path="/register" element={<PublicRoute><Register /></PublicRoute>} />
              <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
              <Route path="/profile" element={<PublicRoute><Profile /></PublicRoute>} />
              <Route path="/forgot-password" element={<PublicRoute><ForgotPassword /></PublicRoute>} />
              <Route path="/reset-password/:token" element={<PublicRoute><ResetPassword /></PublicRoute>} />
              <Route path="/check-your-inbox" element={<PublicRoute><CheckYourInbox /></PublicRoute>} />
              <Route path="/confirm-email/:token" element={<PublicRoute><ConfirmEmail /></PublicRoute>} />

              {/* AUTHENTICATED ONLY ROUTES */}
              <Route path="/update-exchange" element={<AuthenticatedRoute><UpdateExchange /></AuthenticatedRoute>} />
              <Route path="/account" element={<AuthenticatedRoute><Home /></AuthenticatedRoute>} />

              {/* AUTHENTICATED + EXCHANGES ROUTES */}
              <Route path="/dashboard" element={
                <AuthenticatedRoute>
                  <ExchangeRoute>
                    <Dash
                      totalMain={totalMain}
                      totalTrade={totalTrade}
                      totalFutures={totalFutures}
                      setTotalMain={setTotalMain}
                      setTotalTrade={setTotalTrade}
                      setTotalFutures={setTotalFutures}
                    />
                  </ExchangeRoute>
                </AuthenticatedRoute>
              } />

              <Route path="/2fa/setup" element={<AuthenticatedRoute><ExchangeRoute><TwoFactorSetup /></ExchangeRoute></AuthenticatedRoute>} />
              <Route path="/markets" element={<AuthenticatedRoute><ExchangeRoute><ExchangeInfo /></ExchangeRoute></AuthenticatedRoute>} />

              {/* DEFAULT FALLBACK */}
              <Route path="*" element={<RedirectToAvailable />} />
            </Routes>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
