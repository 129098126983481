import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './2fa.css';
import API_URLS from '../../config/apiUrls';

function TwoFactorSetup() {
  const [qrCode, setQrCode] = useState('');
  const [secret, setSecret] = useState('');
  const [token, setToken] = useState('');
  const [deleteToken, setDeleteToken] = useState('');
  const [hasTwoFA, setHasTwoFA] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTwoFAStatus = async () => {
      try {
        const res = await axios.get(API_URLS.TFA_STATUS, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setHasTwoFA(res.data.twoFactorEnabled);
      } catch {
        setMessage('Error checking 2FA status.');
      }
    };
    fetchTwoFAStatus();
  }, []);

  const handleDeleteTwoFA = async () => {
    if (!deleteToken) {
      setMessage('❌ Please enter your current 2FA code to disable it.');
      return;
    }
    try {
      await axios.post(API_URLS.TFA_DELETE, { token: deleteToken }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setHasTwoFA(false);
      setDeleteToken('');
      setMessage('✅ 2FA has been disabled.');
    } catch {
      setMessage('❌ Failed to disable 2FA. Ensure your 2FA code is correct.');
    }
  };


  const handleSetup = async () => {
    try {
      const res = await axios.post(API_URLS.TFA_SETUP, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setQrCode(res.data.qrCodeUrl);
      setSecret(res.data.secret);
      setMessage('Scan the QR Code or use the secret key.');
    } catch {
      setMessage('Error setting up 2FA.');
    }
  };

  const handleVerify = async () => {
    try {
      await axios.post(API_URLS.TFA_VERIFY, { token }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setHasTwoFA(true);
      setMessage('✅ 2FA enabled successfully!');
      navigate('/');
    } catch {
      setMessage('❌ Invalid 2FA code. Please try again.');
    }
  };

  return (
    <div className="tfa-form">
      {hasTwoFA ? (
        <div>
          <p>✅ 2FA is currently enabled. Enter your 2FA code to disable it:</p>
          <input
            className="tfa-input"
            value={deleteToken}
            onChange={(e) => setDeleteToken(e.target.value)}
            placeholder="Enter 2FA Code"
          />
          <button className="tfa-button" onClick={handleDeleteTwoFA}>Disable 2FA</button>
        </div>
      ) : (
        <div>
          <button className="tfa-button-1" onClick={handleSetup}>Generate 2FA QR Code</button>
          {qrCode && (
            <>
              <img className="qrcode" src={qrCode} alt="2FA QR Code" />
              <div className="secret-container">
                <span className="secret-key">{secret}</span>
              </div>
              <input
                className="tfa-input"
                value={token}
                onChange={(e) => setToken(e.target.value)}
                placeholder="Enter 2FA Code"
              />
              <button className="tfa-button" onClick={handleVerify}>Verify and Enable 2FA</button>
            </>
          )}
        </div>
      )}
      {message && <p>{message}</p>}
    </div>
  );
}

export default TwoFactorSetup;
