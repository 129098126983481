import React, { useState, useEffect, createContext } from 'react';

// ✅ Create AuthContext
export const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  // ✅ Authentication State
  const [auth, setAuth] = useState({
    token: localStorage.getItem('token') || null,
    email: localStorage.getItem('email') || null,
    apiKey: localStorage.getItem('apiKey') || null,
  });

  const [authChangeTrigger, setAuthChangeTrigger] = useState(false);
  const [cex, setCex] = useState(false);
  
  // ✅ Functions for Authentication
  const setAuthToken = (token) => {
    const expirationTime = Date.now() + 1000 * 60 * 180; // Example: 1-hour expiration
    localStorage.setItem('token', token);
    localStorage.setItem('tokenExpiration', expirationTime);
    setAuth((prevState) => ({ ...prevState, token }));
    setAuthChangeTrigger((prev) => !prev);
  };
  
  const checkAutoLogout = () => {
    const expirationTime = localStorage.getItem('tokenExpiration');
    if (expirationTime && Date.now() > expirationTime) {
      logout();
    }
  };
  

  const setEmail = (email) => {
    setAuth((prevState) => ({ ...prevState, email }));
    setAuthChangeTrigger((prev) => !prev);
  };

  const setApiKey = (apiKey) => {
    setAuth((prevState) => ({ ...prevState, apiKey }));
    localStorage.setItem('apiKey', apiKey);
  };

  const logout = () => {
    setAuth({ token: null, email: null, apiKey: null });
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    setAuthChangeTrigger((prev) => !prev);
  };
  

  // ✅ Exchange & Trading State
  const [exchange, setExchange] = useState(localStorage.getItem('exchange') || 'kucoin');
  const [pair, setPair] = useState(localStorage.getItem('pair') || 'BTCUSDT');
  const [rawPair, setRawPair] = useState(localStorage.getItem('rawPair') || 'BTCUSDT');

  // ✅ Trading Pairs
  const [tradingPairs, setTradingPairs] = useState(() => {
    const savedPairs = localStorage.getItem('tradingPairs');
    return savedPairs ? JSON.parse(savedPairs) : [];
  });

  // ✅ Exchange Update Function
  const updateExchange = (newExchange) => {
    localStorage.setItem('exchange', newExchange);
    sessionStorage.setItem('exchange', newExchange);
    setExchange(newExchange);
  };

  // ✅ Balance & Exchange Availability
  const [availableBalance, setavailableBalance] = useState(() => {
    const savedBalance = localStorage.getItem('availableBalance');
    return savedBalance ? JSON.parse(savedBalance) : {};
  });

  const [availableExchanges, setAvailableExchanges] = useState(() => {
    const storedExchanges = sessionStorage.getItem('availableExchanges');
    return storedExchanges ? JSON.parse(storedExchanges) : [];
  });

  useEffect(() => {
    const interval = setInterval(() => {
      checkAutoLogout();
    }, 60000); // Check every minute
  
    return () => clearInterval(interval); // Cleanup
  }, []);
  

  // ✅ Save Data Persistently
  useEffect(() => {
    localStorage.setItem('availableBalance', JSON.stringify(availableBalance));
  }, [availableBalance]);

  useEffect(() => {
    sessionStorage.setItem('availableExchanges', JSON.stringify(availableExchanges));
  }, [availableExchanges]);

  useEffect(() => {
    localStorage.setItem('tradingPairs', JSON.stringify(tradingPairs));
  }, [tradingPairs]);

  useEffect(() => {
    localStorage.setItem('exchange', exchange);
    localStorage.setItem('pair', pair);
    localStorage.setItem('rawPair', rawPair);
  }, [exchange, pair, rawPair]);

  useEffect(() => {
    const storedApiKey = localStorage.getItem('apiKey');
    if (storedApiKey) {
      setApiKey(storedApiKey);
    }
  }, []);

  // ✅ Provide Context to Components
  return (
    <AuthContext.Provider value={{
      auth,
      setAuthToken,
      setEmail,
      logout,
      setApiKey,
      authChangeTrigger,
      exchange,
      setExchange: updateExchange,
      pair,
      setPair,
      rawPair,
      setRawPair,
      tradingPairs,
      setTradingPairs,
      availableBalance,
      setavailableBalance,
      cex,
      setCex,
      availableExchanges,
      setAvailableExchanges,
    }}>
      {children}
    </AuthContext.Provider>
  );
}
