import React, { useState, useEffect } from 'react';
import TradingViewChart from '../TradingViewChart';
import CreateBot from '../createBot/CreateBot';
import './TradingPlatform.css';
import MarketPairsDropdown from '../marketPairsDropdown/MarketPairsDropdown';
import ExchangeTickerInfo from '../exchangeTickerInfo/ExchangeTickerInfo';
import ListBots from '../listBots/ListBots';

function TradingPlatform() {
  const [isMobileVertical, setIsMobileVertical] = useState(false);
  const [isBotModalOpen, setIsBotModalOpen] = useState(false);

  useEffect(() => {
    const checkOrientation = () => {
      if (window.innerWidth < 768 && window.innerHeight > window.innerWidth) {
        setIsMobileVertical(true);
      } else {
        setIsMobileVertical(false);
      }
    };

    window.addEventListener("resize", checkOrientation);
    checkOrientation();

    return () => window.removeEventListener("resize", checkOrientation);
  }, []);

  return (
    <div className="trading-platform-container">
      
      {/* Show rotation overlay when in vertical mobile mode */}
      {isMobileVertical && (
        <div className="rotate-screen-overlay">
          <p>Please rotate your device for the best experience</p>
        </div>
      )}

      {/* Normal layout for desktop and horizontal mobile */}
      {!isMobileVertical && (
        <>
          <div className="dropdown-ticker-container">
            <MarketPairsDropdown />
            <ExchangeTickerInfo />
          </div>

          <div className="chart-container">
          </div>
            <TradingViewChart />

          <CreateBot />




          <ListBots />
        </>
      )}

    </div>
  );
}

export default TradingPlatform;
