import React, { useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import API_URLS from '../../config/apiUrls';

function ConfirmEmail() {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true; // Prevents duplicate calls

    const confirmEmail = async () => {
      // console.log(`📩 Sending request with token: ${token}`);

      try {
        const response = await axios.get(
          API_URLS.CONFIRM_EMAIL(token)
        );

        if (!isMounted) return; // Prevents running twice

        console.log(`✅ Server Response:`, response.data);

        if (response.data.message === 'Account is already verified.') {
          alert('Your email is already verified! Redirecting to login...');
        } else {
          alert('Email confirmed successfully!');
        }

        navigate('/login');
      } catch (error) {
        if (!isMounted) return; // Prevents duplicate error messages
        console.error('❌ Error during email confirmation:', error);
        alert('Error during email confirmation. Please try again.');
      }
    };

    if (token) confirmEmail();

    return () => {
      isMounted = false; // Cleanup function to prevent duplicate execution
    };
  }, [token, navigate]);

  return <div>Confirming email...</div>;
}

export default ConfirmEmail;
