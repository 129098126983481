import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { getBinanceExchangeInfo, getKucoinExchangeInfo } from '../../utils/exchangeAPI';
import Select from 'react-select'; // Import the react-select component
import './MarketPairsDropdown.css'; // Import the CSS

function MarketPairsDropdown() {
  const [marketPairs, setMarketPairs] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null); // Add a state variable for the selected option
  const [isLoading, setIsLoading] = useState(true); // Add a loading state
  const { exchange, rawPair, setPair, setRawPair } = useContext(AuthContext);

  useEffect(() => {
    const fetchMarketPairs = async () => {
      setIsLoading(true); // Set loading to true at the start
      try {
        let response;
        if (exchange === 'binance') {
          response = await getBinanceExchangeInfo();
        } else if (exchange === 'kucoin') {
          response = await getKucoinExchangeInfo();
        }
        // Assuming the response data contains an array of market pairs
        const pairs = response.map(pair => ({ value: pair.symbol, label: pair.symbol })); // Map the data to the format required by react-select
        setMarketPairs(pairs);
        setSelectedOption(pairs.find(option => option.value === rawPair)); // Set the selected option after the market pairs have been fetched
      } catch (error) {
        console.error('Error fetching market pairs:', error);
      }
      setIsLoading(false); // Set loading to false once data is fetched
    };

    if (rawPair) { // Only call fetchMarketPairs if rawPair is not null or undefined
      fetchMarketPairs();
    }
  }, [exchange, rawPair]); // Add rawPair to the dependency array

  const handlePairChange = (selectedOption) => {
    setSelectedOption(selectedOption); // Update the selected option when a new option is selected
    const rawPair = selectedOption.value;
    setRawPair(rawPair);
    const pair = rawPair.replace(/[-/]/g, '');
    setPair(pair);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
      borderColor: '#303844',
      color: '#C4C4C4',
      fontSize: '16px',
      outline: 'none',
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: '#303844',
      color: '#c4c4c4',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#C4C4C4',
    }),
    input: (provided) => ({
      ...provided,
      color: 'white', // Set the color of the input text to white
    })
  };

  return (
    <div className="pair-switcher-container">
      {/* <span className="cta-text">Select Pair:</span> */}
      {isLoading ? (
        <p>Loading...</p> // Display a loading message while fetching the data
      ) : (
        <Select 
          className="pair-switcher" 
          options={marketPairs} 
          onChange={handlePairChange} 
          isSearchable={true} // Enable the search functionality
          value={selectedOption} // Use the selectedOption state variable
          styles={customStyles} // Apply the custom styles
        />
      )}
    </div>
  );
}

export default MarketPairsDropdown;
