import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../AuthContext';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Logout from '../logout/Logout';
import ExchangeSwitcher from '../exchangeSwitcher/ExchangeSwitcher';
import { FaBars, FaTimes } from 'react-icons/fa'; // Mobile icons
import logo from '../../images/logo.png';

const Navbar = () => {
  const { auth, availableExchanges, setAvailableExchanges, exchange } = useContext(AuthContext);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const storedExchanges = JSON.parse(sessionStorage.getItem('availableExchanges') || '[]');
    setAvailableExchanges(storedExchanges);
  }, [setAvailableExchanges]);

  // Ensure availableExchanges is always an array
  const validExchanges = Array.isArray(availableExchanges) ? availableExchanges : [];
  const hasExchanges = validExchanges.length > 0 && validExchanges !== 'noExchanges';

  return (
    <header className="navbar">
      <div className="navbar__left">
        <Link to="/">
          <img className="navbar__logo" src={logo} alt="Company Logo" />
        </Link>

        {/* MOBILE MENU BUTTON */}
        <button className="navbar__toggle" onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </button>

        {/* NAVIGATION MENU */}
        <nav className={`navbar__menu ${menuOpen ? 'open' : ''}`}>
          {/* PUBLIC LINKS */}
          <Link to="/markets" onClick={() => setMenuOpen(false)}>MARKETS</Link>

          {/* AUTHENTICATED LINKS (Visible only if logged in) */}
          {auth.token && (
            <>

                  <Link to="/update-exchange" onClick={() => setMenuOpen(false)}>CEXs</Link>
                  <Link to="/dashboard" onClick={() => setMenuOpen(false)}>DASHBOARD</Link>
                  {/* <Link to="/create-bot" onClick={() => setMenuOpen(false)}>CREATE-BOT</Link> */}
                  {/* <Link to="/list-bots" onClick={() => setMenuOpen(false)}>BOTS-LIST</Link> */}
                  <Link to="/2fa/setup" onClick={() => setMenuOpen(false)}>2FA</Link>
                  {/* <p className="exchange-indicator">
                    Active Exchange: <strong>{exchange.charAt(0).toUpperCase() + exchange.slice(1)}</strong>
                  </p> */}

              {/* LOGOUT BUTTON */}
              <Logout />
            </>
          )}

          {/* AUTHENTICATION LINKS (Visible only if NOT logged in) */}
          {!auth.token && (
            <>
              <Link to="/register" onClick={() => setMenuOpen(false)}>REGISTER</Link>
              <Link to="/login" onClick={() => setMenuOpen(false)}>LOGIN</Link>
            </>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
