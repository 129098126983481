import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../AuthContext';
import API_URLS from '../../config/apiUrls';
import './TransferForm.css';

function TransferForm({ currency, availableBalance, onClose }) {
  const [transferData, setTransferData] = useState({
    amount: '',
    recAccountType: '',
  });
  const [responseMessage, setResponseMessage] = useState('');
  const [transferSuccess, setTransferSuccess] = useState(false);
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    setTransferData((prevData) => ({ ...prevData, currency }));
  }, [currency]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTransferData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAccountTypeSelect = (type) => {
    setTransferData((prevData) => ({ ...prevData, recAccountType: type }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(API_URLS.KUCOIN_TRANSFER, 
        { ...transferData, currency }, 
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json',
          },
        });

      const successMessage = res.data.transfer?.msg || 'Transfer successful!';
      setResponseMessage(successMessage);

      if (successMessage.includes('Transfer successful')) {
        setTimeout(() => {
          setTransferSuccess(true);
        }, 500);
      }
    } catch (error) {
      setResponseMessage(error.response?.data?.error || 'An error occurred.');
    }
  };

  const handleBackToDashboard = () => {
    window.location.reload();
  };

  return (
    <div 
      className="modal-overlay"
      onClick={!transferSuccess ? onClose : undefined} // ✅ Prevent closing if transfer is successful
    >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {/* ✅ Close Button at the Top-Right Corner */}
        {!transferSuccess && <button className="close-modal-btn" onClick={onClose}>✖</button>}

        {transferSuccess ? (
          <>
            <h2 className="success-title">Transfer Successful!</h2>
            <button className="transfer-button" onClick={handleBackToDashboard}>
              Back to Dashboard
            </button>
          </>
        ) : (
          <>
            <h2 className="transfer-title">Transfer {currency}</h2>
            <p className="available-balance">Available {currency}: {availableBalance}</p>

            <form className="transfer-form" onSubmit={handleSubmit}>
              <input
                type="number"
                name="amount"
                className="transfer-input"
                placeholder="Amount"
                value={transferData.amount}
                onChange={handleChange}
              />
              <div className="account-type-buttons">
                <button
                  type="button"
                  className={`account-button ${transferData.recAccountType === 'main' ? 'selected' : ''}`}
                  onClick={() => handleAccountTypeSelect('main')}
                >
                  Main
                </button>
                <button
                  type="button"
                  className={`account-button ${transferData.recAccountType === 'trade' ? 'selected' : ''}`}
                  onClick={() => handleAccountTypeSelect('trade')}
                >
                  Trade
                </button>
              </div>
              <button className="transfer-button" type="submit">Submit</button>
            </form>
            {responseMessage && <p className="response-message">{responseMessage}</p>}
          </>
        )}
      </div>
    </div>
  );
}

export default TransferForm;
