import React, { useState, useEffect, useContext } from 'react';
import './Dash.css';
import MainBalanceWithChart from '../balanceWithChart/BalanceWithChart';
import FuturesBalance from '../futureBalanceWithChart/futureBalanceWithChart';
import TradeBalanceWithChart from '../tradeBalanceWithChart/TradeBalanceWithChart';
import { AuthContext } from '../AuthContext';
import API_URLS from '../../config/apiUrls';

function Dash() {
  const { exchange } = useContext(AuthContext);
  
  const [mainBalance, setMainBalance] = useState(0);
  const [tradeBalance, setTradeBalance] = useState(0);
  const [futuresBalance, setFuturesBalance] = useState(0);
  const [initialBalance, setInitialBalance] = useState(0);
  const [loading, setLoading] = useState(true);

  // Fetch Initial Balance Once
  useEffect(() => {
    const fetchInitialBalance = async () => {
      try {
        const response = await fetch(`${API_URLS.INITIAL_EXCHANGE_VALUE}?exchange=${exchange}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch initial balance');
        }

        const data = await response.json();
        setInitialBalance(data.initialBalance || 0);
      } catch (error) {
        console.error('Error fetching initial balance:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialBalance();
  }, [exchange]);

  // Calculate total balance only when a balance value changes
  const currentBalance = mainBalance + tradeBalance + futuresBalance;
  const pnl = initialBalance > 0 ? ((currentBalance - initialBalance) / initialBalance) * 100 : 0;
  const pnlPerc = initialBalance > 0 ? ((currentBalance - initialBalance)) : 0;
  return (
    <div className="dash-container">
      <div className="totals-container">
        <h1>
          <strong>{exchange.charAt(0).toUpperCase() + exchange.slice(1)}:</strong> {currentBalance.toFixed(2)} USDT
        </h1>
        {!loading && (
          <h2>
            <strong>PNL:</strong> {pnlPerc.toFixed(2)} USDT <small> {pnl.toFixed(3)}%</small>
          </h2>
        )}
      </div>

      {/* Load All Components and update balance state separately */}
      <div className="all-balances-container">
        <MainBalanceWithChart onTotalUpdate={setMainBalance} />
        <TradeBalanceWithChart onTotalUpdate={setTradeBalance} />
        <FuturesBalance onTotalUpdate={setFuturesBalance} />
      </div>
    </div>
  );
}

export default Dash;
