import React, { useEffect, useContext } from 'react';
import jstz from 'jstz'; // Make sure to install this package if you haven't
import './TradingViewChart.css'
import { AuthContext } from './AuthContext';

const CONTAINER_ID = "tradingview_chart"; // Define a constant for the container ID

function TradingViewChart() {
  // const [tradingviewLink, setTradingviewLink] = useState('');
  const { exchange, pair } = useContext(AuthContext);

  useEffect(() => {
    const initCandleChart = () => {
      let params = {
        width: '100%',
        height: '475',
        symbol: `${exchange.toUpperCase()}:${pair}`,
        interval: "H",
        timezone: "Etc/UTC",
        theme: "dark",
        style: "1",
        locale: "en",
        toolbar_bg: "#1C212B",
        show_popup_button: true,
        popup_width: 1250,
        popup_height: 1000,
        enable_publishing: false,
        hide_side_toolbar: false,
        container_id: CONTAINER_ID
      };
    
      // Detect a user timezone from the browser 
      let timezone = jstz.determine();
      if (timezone) {
        params.timezone = timezone.name();
      }
    
      // Check if the script is already loaded
      const existingScript = document.querySelector(`script[src="https://s3.tradingview.com/tv.js"]`);
      if (existingScript) {
        new window.TradingView.widget(params);
      } else {
        const script = document.createElement('script');
        script.src = "https://s3.tradingview.com/tv.js";
        script.async = true;
        script.onload = () => {
          new window.TradingView.widget(params);
        };
        document.body.appendChild(script);
      }
    
      // setTradingviewLink(`https://www.tradingview.com/symbols/${pair}/?exchange=${exchange.toUpperCase()}`);
    };
    

    initCandleChart();

    // Cleanup function to remove the script when the component is unmounted
    return () => {
      const script = document.querySelector(`script[src="https://s3.tradingview.com/tv.js"]`);
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [exchange, pair]);

  return (
    <>
      <div>
        <div className='T-container' id={CONTAINER_ID}></div>
      </div>
      {/* <a href={tradingviewLink} target="_blank" rel="noopener noreferrer" className='tradingview'>Open in TradingView</a> */}
    </>
  );
}

export default TradingViewChart;
