import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'; // Import the logout icon

function Logout() {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  const logoutUser = () => {
    // Clear all relevant items from localStorage
    localStorage.clear(); // This will remove all items. If you have other non-user related data, you might want to remove items individually.
    
    logout(); // This will reset all state variables
    navigate('/', {replace: false}); // Redirect to Home page after successful logout
  };

  return (
    <Link to="/" onClick={logoutUser} className="logout-link">
      LOGOUT <FontAwesomeIcon icon={faSignOutAlt} /> {/* Use the FontAwesome icon here */}
    </Link>
  );
}

export default Logout;
