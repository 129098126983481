import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../AuthContext';
import API_URLS from '../../config/apiUrls';
import './TransferForm.css';

function InnerTransferForm({ currency, availableBalance, fromAccount, toAccount, onClose }) {
    const [transferData, setTransferData] = useState({
        amount: '',
        fromAccount: fromAccount,
        toAccount: toAccount,
        currency: currency,
    });
    const [responseMessage, setResponseMessage] = useState('');
    const [transferSuccess, setTransferSuccess] = useState(false);
    const { auth } = useContext(AuthContext);

    useEffect(() => {
        setTransferData(prevData => ({ ...prevData, currency, fromAccount, toAccount }));
    }, [currency, fromAccount, toAccount]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTransferData(prevData => ({ ...prevData, [name]: value }));
    };

    const handlePercentageClick = (percentage) => {
        const calculatedAmount = (availableBalance * (percentage / 100)).toFixed(2);
        setTransferData(prevData => ({ ...prevData, amount: calculatedAmount }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!transferData.amount || isNaN(parseFloat(transferData.amount))) {
            setResponseMessage("Please enter a valid amount.");
            return;
        }
    
        const requestData = {
            amount: parseFloat(transferData.amount),
            currency: currency,
            fromAccount: fromAccount,
            toAccount: toAccount,
        };
    
        console.log("📤 Frontend Request Data:", requestData);  // ✅ Debugging log
    
        try {
            const res = await axios.post(API_URLS.INNER_TRANSFER, requestData, {
                headers: {
                    Authorization: `Bearer ${auth.token}`,
                    'Content-Type': 'application/json',
                },
            });
    
            console.log("✅ Transfer API Response:", res.data);  // ✅ Debugging log
            setResponseMessage(res.data.message || "Transfer successful!");
    
            if (res.data.message.includes("Transfer successful")) {
                setTimeout(() => {
                    setTransferSuccess(true);
                }, 500);
            }
        } catch (error) {
            console.error("❌ Transfer API Error:", error.response?.data || error.message);
            setResponseMessage(error.response?.data?.error || "An error occurred.");
        }
    };

    const handleBackToDashboard = () => {
        window.location.reload();
    };

    return (
        <div 
            className="modal-overlay"
            onClick={!transferSuccess ? onClose : undefined} // ✅ Prevent closing if transfer is successful
        >
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                {/* ✅ Close Button at the Top-Right Corner */}
                {!transferSuccess && <button className="close-modal-btn" onClick={onClose}>✖</button>}

                {transferSuccess ? (
                    <>
                        <h2 className="success-title">Transfer Successful!</h2>
                        <button className="transfer-button" onClick={handleBackToDashboard}>
                            Back to Dashboard
                        </button>
                    </>
                ) : (
                    <>
                        <h2 className="transfer-title">Transfer {currency}</h2>
                        <p className="available-balance">Available {currency}: {availableBalance}</p>
                        <form className="transfer-form" onSubmit={handleSubmit}>
                            <input 
                                type="number" 
                                name="amount" 
                                className="transfer-input" 
                                placeholder="Amount" 
                                value={transferData.amount} 
                                onChange={handleChange} 
                            />
                            <div className="percentage-buttons">
                                {[10, 25, 50, 75, 100].map(percent => (
                                    <button 
                                        key={percent} 
                                        type="button" 
                                        className="percentage-btn" 
                                        onClick={() => handlePercentageClick(percent)}
                                    >
                                        {percent}%
                                    </button>
                                ))}
                            </div>

                            <button className="transfer-button" type="submit">Submit</button>
                        </form>
                        {responseMessage && <p className="response-message">{responseMessage}</p>}
                    </>
                )}
            </div>
        </div>
    );
}

export default InnerTransferForm;
