import React from "react";
import { Link } from "react-router-dom";
import { FaRobot, FaShieldAlt, FaExchangeAlt, FaChartLine } from "react-icons/fa";
import "./JoePesciComponent.css"; // Import the updated styles
import TopMovers from "../topMovers/TopMovers";


export default function JoePesciComponent() {
  return (
    <div className="page-container">

      {/* HERO SECTION */}
      <div className="hero">
        <h1>Non-Custodial Automated Trading</h1>
        <p>Trade smarter with AI-driven automation while keeping full control of your funds.</p>
        <Link to="/register">
          <button className="hero-button">Start Trading Now</button>
        </Link>
      </div>
      
      <TopMovers/>

      

      {/* HOW IT WORKS */}
      <section className="section">
        <h2>How It Works</h2>
        <div className="grid">
          <div className="grid-item work-step step-1">
            <FaExchangeAlt className="icon" />
            <h3>1. Connect Your Exchange</h3>
            <p>Securely link Binance or KuCoin API keys with no withdrawal permissions.</p>
          </div>
          <div className="grid-item work-step step-2">
            <FaRobot className="icon" />
            <h3>2. Set Up Your Strategy</h3>
            <p>Choose from spot trading, futures, or rebalancing with technical indicators.</p>
          </div>
          <div className="grid-item work-step step-3">
            <FaChartLine className="icon" />
            <h3>3. Automate & Profit</h3>
            <p>Let your bots handle the trades while you track performance in real-time.</p>
          </div>
        </div>
      </section>

      {/* FEATURES */}
      <section className="section features">
        <h2>Why Choose BMYBIT?</h2>
        <div className="grid">
          <div className="grid-item feature-box feature-1">
            <FaShieldAlt className="icon" />
            <h3>No Custody of Funds</h3>
            <p>You retain full control. We never access or withdraw your funds.</p>
          </div>
          <div className="grid-item feature-box feature-2">
            <FaExchangeAlt className="icon" />
            <h3>Supports Binance & KuCoin</h3>
            <p>Trade seamlessly on top exchanges via API integration.</p>
          </div>
          <div className="grid-item feature-box feature-3">
            <FaRobot className="icon" />
            <h3>Smart Trading Bots</h3>
            <p>Use RSI, EMA, Bollinger Bands, and AI-powered strategies.</p>
          </div>
        </div>
      </section>

      {/* FAQ */}
      <section className="section faq">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-container">
          <div className="faq-item">
            <h3>Is BMYBIT a regulated platform?</h3>
            <p>No, BMYBIT is a software provider. We do not offer financial services or hold user funds.</p>
          </div>
          <div className="faq-item">
            <h3>Do I need to verify my identity?</h3>
            <p>No, KYC is handled by the exchange. We do not store user identities.</p>
          </div>
          <div className="faq-item">
            <h3>Are my API keys secure?</h3>
            <p>Yes! API keys should have <b>no withdrawal permissions</b>. They are stored securely.</p>
          </div>
        </div>
      </section>

      {/* CALL TO ACTION */}
      <div className="cta">
        <h2>Start Automating Your Trading Today</h2>
        <p>Sign up and launch your first bot in minutes.</p>
        <Link to="/register">
          <button className="cta-button">Get Started</button>
        </Link>
      </div>
    </div>
  );
}
