import React, { useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { Link } from 'react-router-dom';
import './Title.css';

const Title = () => {
  const { auth } = useContext(AuthContext);
  return (
    <div className='title-container'>
        <h1 className="title">BmyBit (beta)</h1>
        <p>
        <Link to="/login">Login</Link> or
        <br/><br/>
        {!auth.token && <Link to="/register">Create Account</Link>} 
        </p>

    </div>
  );
};

export default Title;
