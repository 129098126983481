import React, { useContext } from 'react';
import { AuthContext } from '../AuthContext';
import Title from '../title/Title';
import UpdateExchange from '../updateExchange/UpdateExchange';
import './AccountAPI.css';

function Home() {
  const { auth } = useContext(AuthContext);

  return (
    <div className="home-container">
      {!auth.token && <Title />}
      {auth.token && (
        <div>
          <h2>API Key:</h2>
          <p>{auth.apiKey}</p>
        </div>
      )}
    </div>
  );
}

export default Home;
