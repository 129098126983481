// const BASE_URL = "https://bmybit-e1c10c9ea4ca.herokuapp.com/api/user";
// const BASE_URL = "http://localhost:5002/api/user";
const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:5002/api/user";


const API_URLS = {
  // 🔹 Authentication
  REGISTER: `${BASE_URL}/user/register`,
  LOGIN: `${BASE_URL}/login`,
  FORGOT_PASSWORD: `${BASE_URL}/forgot-password`,
  RESET_PASSWORD: (token) => `${BASE_URL}/reset-password/${token}`,
  CONFIRM_EMAIL: (token) => `${BASE_URL}/confirm-email/${token}`,

  // 🔹 2FA Authentication
  TFA_SETUP: `${BASE_URL}/2fa/setup`,
  TFA_VERIFY: `${BASE_URL}/2fa/verify`,
  TFA_VERIFY_LOGIN: `${BASE_URL}/2fa/verify-login`,
  TFA_STATUS: `${BASE_URL}/2fa/status`,
  TFA_DELETE: `${BASE_URL}/2fa/delete`,

  // 🔹 User & Profile Management
  DELETE: `${BASE_URL}/delete`,
  UPDATE_EXCHANGE: `${BASE_URL}/update-exchange`,

  // 🔹 Balances
  FUTURES_BALANCE: `${BASE_URL}/futures-balance`,
  BALANCE: `${BASE_URL}/balance`,
  MAIN_BALANCE: `${BASE_URL}/main-balance`,
  TRADE_BALANCE: `${BASE_URL}/trade-balance`,
  KUCOIN_VALUE: `${BASE_URL}/kucoin/total-value`,
  INITIAL_EXCHANGE_VALUE: `${BASE_URL}/exchange-balance`,

  // 🔹 Transfers
  INNER_TRANSFER: `${BASE_URL}/kucoin/inner-transfer`,
  KUCOIN_TRANSFER: `${BASE_URL}/kucoin/transfer`,
  KUCOIN_FUTURES_TRANSFER: `${BASE_URL}/kucoin/transfer-to-futures`,

  // 🔹 Trading Pairs
  TRADING_PAIRS: `${BASE_URL}/trading-pairs`,

  // 🔹 Trading Pairs
  FIAT_PRICES: `${BASE_URL}/total-balance-fiat`,
  KUCOIN_FIAT_PRICES: `${BASE_URL}/total-balance-fiat`,

  // 🔹 Bots
  CREATE_BOT: `${BASE_URL}/create-bot`,
  DELETE_BOT: (botId) => `${BASE_URL}/delete-bot/${botId}`,
  PAUSE_BOT: (botId) => `${BASE_URL}/pause-bot/${botId}`,
  PLAY_BOT: (botId) => `${BASE_URL}/play-bot/${botId}`,
  LIST_BOTS: `${BASE_URL}/list-bots`,

  // 🔹 Portfolio & Trading
  CREATE_PORTFOLIO: `${BASE_URL}/create-portfolio`,
  CREATE_CURRENCY_BOT: `${BASE_URL}/create-currency-bot`,

  // 🔹 Exchange Availability
  EXCHANGES_AVAILABILITY: `${BASE_URL}/exchanges/availability`,

  // 🔹 Membership
  UPDATE_MEMBERSHIP: `${BASE_URL}/update-membership`,
};

export default API_URLS;
