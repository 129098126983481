import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../AuthContext';
import './CreateBot.css';
import API_URLS from '../../config/apiUrls';

function CreateBot() {
  const [symbol, setSymbol] = useState('');
  const [initialAsset, setInitialAsset] = useState('');
  const [side, setSide] = useState('');
  const [initialQuantity, setInitialQuantity] = useState('');
  const [stopLoss, setStopLoss] = useState('0.5');
  const [overB, setOverB] = useState(70);
  const [overS, setOverS] = useState(30);
  // Calculate tomorrow's date
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  // Format the date to 'yyyy-mm-dd'
  const formattedTomorrow = tomorrow.toISOString().split('T')[0];

  // Set the default value of expirationDate to tomorrow's date
  const [expirationDate, setExpirationDate] = useState(formattedTomorrow);
  const { auth, exchange, availableBalance  } = useContext(AuthContext);
  const { tradingPairs, rawPair  } = useContext(AuthContext);
  const [stopLossError, setStopLossError] = useState(false); // New state variable to toggle error state
  const selectedPair = tradingPairs.find(pair => pair.symbol === rawPair);

  useEffect(() => {
    if (selectedPair) {
      setSymbol(selectedPair.symbol);
      
      // Set initial values for initialAsset and side
      if (selectedPair.baseAsset && selectedPair.quoteAsset) {
        setInitialAsset(selectedPair.baseAsset); // Set to baseAsset as default
        setSide('sell'); // Set to 'sell' as default when baseAsset is selected
        
        // Set initialQuantity based on initialAsset and selectedPair
        const minQuantity = initialAsset === selectedPair?.quoteAsset ? Number(selectedPair?.filters[6]?.minNotional) : Number(selectedPair?.filters[1]?.minQty);
        setInitialQuantity(minQuantity || ''); // Set to minQuantity if it exists, else set to an empty string
      }
    }
    // eslint-disable-next-line
  }, [selectedPair]);

  useEffect(() => {
    if (selectedPair && initialAsset) {
      // Update initialQuantity based on the new initialAsset and selectedPair
      const minQuantity = initialAsset === selectedPair?.quoteAsset ? Number(selectedPair?.filters[6]?.minNotional) : Number(selectedPair?.filters[1]?.minQty);
      setInitialQuantity(minQuantity || ''); // Set to minQuantity if it exists, else set to an empty string
    }
  }, [selectedPair, initialAsset]);
  
  
  

  // Find the available balance for baseAsset and quoteAsset
  // eslint-disable-next-line
  const baseAssetBalance = Number((availableBalance[selectedPair?.baseAsset] || 0)).toFixed(8);
  // eslint-disable-next-line
  const quoteAssetBalance = Number((availableBalance[selectedPair?.quoteAsset] || 0)).toFixed(8);

  const handleChangeInitialAsset = (e) => {
    const selectedAsset = e.target.value;
    setInitialAsset(selectedAsset);
    
    if (selectedAsset === selectedPair?.baseAsset) {
      console.log(selectedPair?.baseAsset)
      setSide('sell');
    } else if (selectedAsset === selectedPair?.quoteAsset) {
      console.log(selectedPair?.quoteAsset)
      setSide('buy');
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate initialAsset and side before submitting
    if (!initialAsset || !side) {
      alert('Please select Initial Asset and Side before submitting.');
      return;
    }
  
    const stopLossDecimal = parseFloat(stopLoss) / 100;
    if (isNaN(stopLossDecimal) || stopLossDecimal < 0.005 || stopLossDecimal > 1) {
      setStopLossError(true); // Set error state instead of alert
      return;
    }
  
    try {
      const response = await axios.post(
        API_URLS.CREATE_BOT,
        { 
          exchange, 
          symbol, 
          side, 
          initialAsset, 
          initialQuantity, 
          overB, 
          overS, 
          expirationDate, 
          stopLoss: stopLossDecimal // Send as decimal
        },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
    
      console.log(response.data);
      alert('Bot created successfully');
    } catch (error) {
      console.error(error);
    }
  };
  

  return (
    <div className="CreateBotContainer">
      <form onSubmit={handleSubmit} className="CreateBot">
        <h3>Create RSI bot with {rawPair}</h3>
        {/* {selectedPair ? (
          <div>
            <p>Available Balance</p>
            <p>{selectedPair.baseAsset}: {baseAssetBalance} | {selectedPair.quoteAsset}: {quoteAssetBalance}</p>
            <br></br>
          </div>
        ) : (
          <p></p>
        )} */}
        <div className="input-row">
          <div>
            <label>Initial Asset:</label>
            {selectedPair ? (
              <select value={initialAsset} onChange={handleChangeInitialAsset} style={{ backgroundColor: '#1C212B', color: 'white' }}>
                <option value={selectedPair.baseAsset}>{selectedPair.baseAsset}</option>
                <option value={selectedPair.quoteAsset}>{selectedPair.quoteAsset}</option>
              </select>
            ) : (
              <p>No trading pair selected</p>
            )}
          </div>
          <div>
            <label>Quantity:</label>
            {selectedPair && selectedPair.filters && (
              <input 
                type="number" 
                min={initialAsset === selectedPair?.quoteAsset ? Number(selectedPair?.filters[6]?.minNotional) : Number(selectedPair?.filters[1]?.minQty)}
                value={initialQuantity} 
                onChange={(e) => setInitialQuantity(e.target.value)} 
              />
            )}

          </div>
        </div>

        <div className="input-row">
          <div>
            <label>Over Bought:</label>
            <input 
              type="number"
              min={2}
              value={overB} 
              onChange={(e) => {
                const newOverB = Number(e.target.value);
                if(newOverB <= overS) {
                  setOverS(newOverB - 1); // Adjust overS to be one less than newOverB
                }
                setOverB(newOverB);
              }} 
            />
          </div>
          <div>
            <label>Over Sold:</label>
            <input 
              type="number"
              min={1}
              value={overS} 
              onChange={(e) => {
                const newOverS = Number(e.target.value);
                if(newOverS >= overB) {
                  setOverB(newOverS + 1); // Adjust overB to be one more than newOverS
                }
                setOverS(newOverS);
              }} 
            />
          </div>
        </div>

        <div className="input-row">
          <div>
            <label>Stop Loss:</label>
            <input 
              type="text"
              value={stopLoss ? `${stopLoss}%` : ''} 
              className={stopLossError ? 'error' : ''} // Apply error class conditionally
              onChange={(e) => {
                const value = e.target.value.replace('%', '');
                if (!isNaN(value) || value === '') {
                  setStopLoss(value);
                  setStopLossError(false); // Reset error state on value change
                }
              }} 
            />
            {stopLossError && <p className="error-text">only values between 0.5% and 100%</p>} 
          </div>
          <div>
            <label>Ends on:</label>
            <input type="date" value={expirationDate} onChange={(e) => setExpirationDate(e.target.value)} />
          </div>
        </div>
        
        <button className='CreateBotButton' type="submit">Start Trading Bot</button>
      </form>
      {exchange === 'kucoin' && (
        <div className="ComingSoonOverlay">
          <span>Kucoin bots will be available soon</span>
        </div>
      )}
    </div>
  );
}

export default CreateBot;


