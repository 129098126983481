import React, { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../AuthContext';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import './futureBalanceWithChart.css';
import API_URLS from '../../config/apiUrls';
import TransferForm from '../transferForm/TransferForm';
import { FaExchangeAlt } from 'react-icons/fa'; // Import transfer icon

// Register the required Chart.js components and plugins
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

function FuturesBalance({ onTotalUpdate}) {
  const [futuresBalances, setFuturesBalances] = useState([]);
  const [usdtValues, setUsdtValues] = useState({});
  const [loading, setLoading] = useState(false);
  const { auth, exchange } = useContext(AuthContext);
  const selectedCurrencies = ["USDT", "BTC", "ETH", "XRP"];
  const [selectedCurrency, setSelectedCurrency] = useState(null); // Track selected currency for transfer
  const [showTransferModal, setShowTransferModal] = useState(false); // Toggle modal visibility
  const [availableBalance, setAvailableBalance] = useState(null);

  const futuresBalanceUrl = API_URLS.FUTURES_BALANCE;

  // Open transfer modal and set currency
  const handleTransferClick = (currency, freeBalance) => {
    setSelectedCurrency(currency);
    setAvailableBalance(freeBalance); // ✅ Store the available balance
    setShowTransferModal(true);
  };
  

  // Fetch Futures Balance
  const fetchFuturesBalance = useCallback(async () => {
    try {
      setLoading(true);
      // console.log('Fetching futures balance...');
  
      // Adding the missing 'currency' parameter
      const response = await axios.get(futuresBalanceUrl, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        params: {
          exchange,
          currency: 'USDT', // Pass the required 'currency' parameter
        },
      });
  
      // console.log('Response data:', response.data);
  
      // Normalize the response data into an array
      const rawBalances = response.data;
      const normalizedBalances = Object.entries(rawBalances).map(([asset, details]) => ({
        asset,
        ...details,
      }));
  
      // console.log('Normalized Balances:', normalizedBalances);
      setFuturesBalances(normalizedBalances);
    } catch (err) {
      console.error('Error fetching futures balances:', err.response?.data || err.message);
      setFuturesBalances([]);
    } finally {
      setLoading(false);
    }
    }, [auth.token, exchange]);
    

    useEffect(() => {
        fetchFuturesBalance();
    }, [fetchFuturesBalance]);

    // Calculate USDT Value
    const calculateUSDTValue = useCallback(() => {
        if (!futuresBalances.length) {
        // console.log('No futures balances available to calculate USDT value.');
        return;
        }

        // console.log('Calculating USDT values for balances:', futuresBalances);

        const values = {};
        futuresBalances.forEach((balance) => {
        const { asset, accountEquity } = balance;
        const totalBalance = parseFloat(accountEquity || 0); // Total is accountEquity
        if (totalBalance > 0) {
            values[asset] = totalBalance; // Directly use the total value for now
        }
        });

        // console.log('Calculated USDT Values:', values);
        setUsdtValues(values);
    }, [futuresBalances]);

    useEffect(() => {
      console.log('Fetched Futures Balances:', futuresBalances[0]);
      calculateUSDTValue();
  }, [futuresBalances, calculateUSDTValue]);
  
  useEffect(() => {
      console.log('Updated USDT Values:', usdtValues);
  }, [usdtValues]);
  

  

// Extract accountEquity for all selected currencies (same logic as table)
const filteredUsdtValues = selectedCurrencies.reduce((acc, currency) => {
  const balanceData = futuresBalances[0]?.[currency]; // Access balance data

  if (balanceData && parseFloat(balanceData.usdtValue) > 0) {
    acc[currency] = parseFloat(balanceData.usdtValue);
  }

  return acc;
}, {});

// Calculate total filtered futures value
const totalFilteredFuturesValue = Object.values(filteredUsdtValues).reduce(
  (acc, value) => acc + value,
  0
);

useEffect(() => {
  onTotalUpdate(totalFilteredFuturesValue);
}, [totalFilteredFuturesValue, onTotalUpdate]);

// Ensure the chart only renders when there is valid data
const chartData =
  totalFilteredFuturesValue > 0
    ? {
        labels: Object.keys(filteredUsdtValues),
        datasets: [
          {
            data: Object.values(filteredUsdtValues),
            backgroundColor: [
              '#FF6384',
              '#36A2EB',
              '#FFCE56',
              '#4BC0C0',
              '#9966FF',
              '#FF9F40',
            ],
          },
        ],
      }
    : null;


// Chart Options
const chartOptions = {
  plugins: {
    datalabels: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          const label = context.label || '';
          const value = context.raw || 0;
          const percentage =
            totalFilteredFuturesValue > 0
              ? ((value / totalFilteredFuturesValue) * 100).toFixed(2) + '%'
              : '0%';
          return `${label}: ${value.toFixed(2)} USDT (${percentage})`;
        },
      },
    },
  },
};

const formatNumber = (num) => {
  if (!num) return "0"; // Handle null or undefined cases
  let formatted = parseFloat(num).toFixed(12); // Fix to 8 decimals
  formatted = formatted.replace(/\.?0+$/, ""); // Remove trailing zeros
  return formatted.replace(".", ","); // Replace dot with comma
};



  return (
    <div className="balance-with-chart">
      <h4>Futures: {totalFilteredFuturesValue.toFixed(2)} USDT</h4>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="balance-chart-container">
          
          <div className="balance-table">
            <table>
              <thead>
                <tr>
                  <th>Contract</th>
                  <th>Equity</th>
                  <th>Free</th>
                  <th>Frozen</th>
                  <th>Position</th>
                  <th>UnrealisedPNL</th>
                  <th>Total$</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {selectedCurrencies
                  .filter(currency => {
                    const balanceData = futuresBalances[0]?.[currency];
                    return balanceData && parseFloat(balanceData.accountEquity) > 0; // ✅ Only keep rows where accountEquity > 0
                  })
                  .map((currency) => {
                    const balanceData = futuresBalances[0]?.[currency];

                    return (
                      <tr key={currency}>
                        <td>{balanceData.currency}</td>
                        <td>{formatNumber(balanceData.accountEquity || 0)}</td>
                        <td>{formatNumber(balanceData.availableBalance || 0)}</td>
                        <td>{formatNumber(balanceData.frozenFunds || 0)}</td>
                        <td>{formatNumber(balanceData.positionMargin || 0)}</td>
                        <td>{formatNumber(balanceData.unrealisedPNL || 0)}</td>
                        
                        <td>${parseFloat(balanceData.usdtValue || 0).toFixed(2)}</td>
                        <td>
                          {parseFloat(balanceData.availableBalance) > 0 && (
                            <button 
                              className="transfer-btn" 
                              onClick={() => handleTransferClick(currency, parseFloat(balanceData.availableBalance))}
                            >
                              <span className="transfer-text">Transfer</span>
                              <FaExchangeAlt className="transfer-icon" />
                            </button>
                          )}
                        </td>

                      </tr>
                    );
                  })}
              </tbody>

            </table>
          </div>

          {/* Chart Section - Moved ABOVE the table */}
          <div className="balance-chart">
            {chartData ? (
              <Pie data={chartData} options={chartOptions} />
            ) : (
              <p>No data to display</p>
            )}
          </div>

          {/* Table Section */}
        </div>
      )}

{showTransferModal && (
  <div className="modal-overlay" onClick={() => setShowTransferModal(false)}>
    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
      <button className="close-modal-btn" onClick={() => setShowTransferModal(false)}>✖</button>
      <TransferForm 
        currency={selectedCurrency} 
        availableBalance={availableBalance} // ✅ Pass available balance
        onClose={() => setShowTransferModal(false)} 
      />
    </div>
  </div>
)}

    </div>

  );
}

export default FuturesBalance;
