import React, { useState } from 'react';
import './Register.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import API_URLS from '../../config/apiUrls';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const navigate = useNavigate();

  const register = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage(null);

    // Email validation
    const emailValidationRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z|a-z]{2,}$/i;
    if (!email || !emailValidationRegex.test(email)) {
      setLoading(false);
      return setErrorMessage('Please enter a valid email address');
    }

    if (!password || !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(password)) {
      setLoading(false);
      return setErrorMessage('Password must contain at least 1 lowercase, 1 uppercase, 1 numeric, 1 special char, and be at least 8 chars long');
    }

    if (password !== confirmPassword) {
      setLoading(false);
      return setErrorMessage('Passwords do not match');
    }

    if (!termsAccepted) {
      setLoading(false);
      return setErrorMessage('You must accept the Terms & Conditions to register');
    }

    try {
      await axios.post(API_URLS.REGISTER, { email, password });
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      navigate('/check-your-inbox', {replace: false});
    } catch (err) {
      if (err.response && err.response.status === 400 && err.response.data.error) {
        setErrorMessage(err.response.data.error);
      } else {
        console.log(err);
        setErrorMessage('An error occurred. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form className='register-form' onSubmit={register}>
        <h2>Register</h2>
        {errorMessage && <p className="error">{errorMessage}</p>}
        <input className="register-input" type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        <input className="register-input" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        <input className="register-input" type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />

        <div className="terms-container">
          <input 
            type="checkbox" 
            id="terms" 
            checked={termsAccepted} 
            onChange={() => setTermsAccepted(!termsAccepted)}
          />
          <label htmlFor="terms">
            I agree to the 
            <span className="terms-link" onClick={() => setShowTermsModal(true)}> Terms & Conditions</span>
          </label>
        </div>

        <button className="register-button" type="submit" disabled={loading}>
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Register'}
        </button>
      </form>

      {/* Terms & Conditions Modal */}
      {showTermsModal && (
        <div className="modal-overlay">
        <div className="modal-content">
          <h3>BMYBIT LTD - Terms of Use</h3>
          <div className="terms-scrollable">
            <p><strong>Effective Date: 11.02.2025</strong></p>
      
            <p><strong>1. INTRODUCTION</strong><br />
              1.1. Company Information: BMYBIT LTD is a company registered in England and Wales with company number 09931897, located at 54 Kestrel Avenue, London, England, SE24 0EB.<br />
              1.2. Scope of Services: BMYBIT LTD (“BMYBIT,” “we,” “us,” or “our”) provides software that enables users to connect their personal cryptocurrency exchange accounts to automate trading strategies via API keys. We do not provide brokerage, exchange, transfer, wallet, or custodial services.<br />
              1.3. Binding Agreement: By creating an account and using our software, you acknowledge and agree to these Terms of Use, including our Privacy Policy.<br />
              1.4. No Financial Services: BMYBIT LTD provides software only. Any references to trading, exchange, transfer, or wallet services are references to third-party service providers.
            </p>
      
            <p><strong>2. DEFINITIONS</strong><br />
              Software: The automated trading platform provided by BMYBIT LTD.<br />
              Client Account: The user account created on BMYBIT.<br />
              Exchange Account: The cryptocurrency exchange account linked to the Software via API.<br />
              Subscription: A time-based or pay-per-bot license for Software access.<br />
              API Key: A unique authentication token issued by a cryptocurrency exchange to allow Software access to trade on the user’s behalf.
            </p>
      
            <p><strong>3. SERVICES PROVIDED</strong><br />
              3.1. Automated Trading: Rebalancing, Spot Trading Automation, Futures Trading Automation.<br />
              3.2. Third-Party Exchange APIs: Users must connect a supported cryptocurrency exchange via API. BMYBIT does not store or have access to user funds.<br />
              3.3. Paid Features: Some functionalities may require a subscription or pay-per-bot model.<br />
              3.4. No Custody: BMYBIT does not hold, manage, or control user funds.
            </p>
      
            <p><strong>4. ACCOUNT ELIGIBILITY & USAGE</strong><br />
              4.1. Minimum Requirements: Users must be at least 18 years old.<br />
              4.2. Exchange Account Responsibility: Users must maintain a valid exchange account.<br />
              4.3. Account Security: Users are responsible for the security of their API keys.<br />
              4.4. Prohibited Activities: No third-party account management, market manipulation, or reverse-engineering of the Software.
            </p>
      
            <p><strong>5. SUBSCRIPTIONS & PAYMENTS</strong><br />
              5.1. Subscription Model: Time-Based Subscriptions & Pay-Per-Bot options.<br />
              5.2. Automatic Renewal: Subscriptions renew automatically unless canceled.<br />
              5.3. No Refunds: All purchases are final.<br />
              5.4. Payment Failure: Accounts may be suspended if payments fail.
            </p>
      
            <p><strong>6. DISCLAIMER OF LIABILITY</strong><br />
              6.1. Trading Risks: Cryptocurrency trading is high risk.<br />
              6.2. No Financial Advice: BMYBIT provides tools, not financial advice.<br />
              6.3. Exchange API Failures: We are not responsible for exchange API downtime.<br />
              6.4. Software Availability: While we strive for uptime, service interruptions may occur.<br />
              6.5. User Responsibility: Users configure bots at their own risk.
            </p>
      
            <p><strong>7. TERMINATION & ACCOUNT SUSPENSION</strong><br />
              7.1. BMYBIT’s Right to Terminate: We may suspend accounts for violations.<br />
              7.2. User Termination: Users may close their account at any time, but no refunds will be issued.<br />
              7.3. Inactive Accounts: Accounts inactive for 12 months may be deleted.
            </p>
      
            <p><strong>8. DISPUTE RESOLUTION</strong><br />
              8.1. Complaints Handling: Users can submit complaints via support@bmybit.com.<br />
              8.2. Governing Law: These Terms are governed by UK law.<br />
              8.3. Jurisdiction: Disputes shall be resolved in UK courts.
            </p>
      
            <p><strong>9. CHANGES TO THESE TERMS</strong><br />
              9.1. Modifications: BMYBIT may update these Terms at any time.<br />
              9.2. User Acceptance: Continued use of the Software after changes implies acceptance.
            </p>
      
            <p><strong>10. CONTACT INFORMATION</strong><br />
              Company Name: BMYBIT LTD<br />
              Registered Address: 54 Kestrel Avenue, London, England, SE24 0EB<br />
              Company Number: 09931897<br />
              Email: support@bmybit.com
            </p>
          </div>
          <button className="close-modal" onClick={() => setShowTermsModal(false)}>Close</button>
        </div>
      </div>
      
      )}
    </>
  );
}

export default Register;
