import axios from 'axios';

export const getBinanceExchangeInfo = async () => {
  try {
    const response = await axios.get(' https://data-api.binance.vision/api/v3/ticker/24hr');
    return response.data;
  } catch (error) {
    console.error('Error fetching Binance exchange info:', error);
    throw error;
  }
};

// export const getKucoinExchangeInfo = async () => {
//   try {
//     const response = await axios.get('https://bmybit-e1c10c9ea4ca.herokuapp.com/api/kucoin/market/allTickers');
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching Kucoin exchange info:', error);
//     throw error;
//   }
// };

export const getBinanceOrderBook = async (symbol) => {
  try {
    const response = await axios.get(`https://bmybit-e1c10c9ea4ca.herokuapp.com/api/binance/depth?symbol=${symbol}&limit=100`);
    return response.data;
  } catch (error) {
    console.error('Error fetching Binance order book:', error);
    throw error;
  }
};

// export const getKucoinOrderBook = async (symbol) => {
//   try {
//     const response = await axios.get(`https://bmybit-e1c10c9ea4ca.herokuapp.com/api/kucoin/orderbook/level2_100?symbol=${symbol}`);
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching Kucoin order book:', error);
//     throw error;
//   }
// };

export const getBinanceTickerPrice = async (symbol) => {
  try {
    const response = await axios.get(` https://data-api.binance.vision/api/v3/ticker/24hr?symbol=${symbol}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching Binance ticker price:', error);
    throw error;
  }
};

// export const getKucoinTickerPrice = async (symbol) => {
//   try {
//     const response = await axios.get(`https://bmybit-e1c10c9ea4ca.herokuapp.com/api/kucoin/ticker?symbol=${symbol}`);
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching Kucoin ticker price:', error);
//     throw error;
//   }
// };






export const getKucoinExchangeInfo = async () => {
  try {
    const response = await axios.get('https://bmybit-e1c10c9ea4ca.herokuapp.com/api/kucoin/market/allTickers');
    return response.data;
  } catch (error) {
    console.error('Error fetching KuCoin exchange info:', error);
    throw error;
  }
};

export const getKucoinOrderBook = async (symbol) => {
  try {
    const response = await axios.get(`https://bmybit-e1c10c9ea4ca.herokuapp.com/api/kucoin/orderbook/level2_100?symbol=${symbol}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching KuCoin order book:', error);
    throw error;
  }
};

export const getKucoinTickerPrice = async (symbol) => {
  try {
    const response = await axios.get(`https://bmybit-e1c10c9ea4ca.herokuapp.com/api/kucoin/ticker?symbol=${symbol}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching KuCoin ticker price:', error);
    throw error;
  }
};

export const getKucoinRecentTrades = async (symbol) => {
  try {
    const response = await axios.get(`https://bmybit-e1c10c9ea4ca.herokuapp.com/api/kucoin/trades?symbol=${symbol}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching KuCoin recent trades:', error);
    throw error;
  }
};

export const getKucoinMarketStats = async (symbol) => {
  try {
    const response = await axios.get(`https://bmybit-e1c10c9ea4ca.herokuapp.com/api/kucoin/stats?symbol=${symbol}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching KuCoin market stats:', error);
    throw error;
  }
};

export const getKucoin24hrChange = async () => {
  try {
    const response = await axios.get('https://bmybit-e1c10c9ea4ca.herokuapp.com/api/kucoin/market/stats');
    return response.data;
  } catch (error) {
    console.error('Error fetching KuCoin 24hr change:', error);
    throw error;
  }
};
