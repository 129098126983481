import React, { useContext } from 'react';
import { AuthContext } from '../AuthContext';
// import DeleteAccount from '../DeleteAccount';
import './Profile.css';

const Profile = () => {
  const { auth } = useContext(AuthContext);

  return (
    <div className="profile-container">
      {auth.token && (
        <div className="profile-details">
          <h2>API Key:</h2> 
          <p>{auth.apiKey}</p>
          {/* <DeleteAccount /> */}
        </div>
      )}
    </div>
  );
}

export default Profile;
