// checkYourInbox
import React from 'react';
import './Register.css';

function CheckYourInbox() {
    return (
      <div className='register-form'>
        <h2>Confirm Your Email</h2>
        <p>Please check your inbox for a confirmation email.</p>
      </div>
    );
}

export default CheckYourInbox;
