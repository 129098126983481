import React, { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../AuthContext';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import './BalanceWithChart.css';
import TransferToFuturesForm from '../transferForm/TransferToFutures';
import InnerTransferForm from '../transferForm/TransferInner';
import { FaExchangeAlt, FaRetweet } from 'react-icons/fa';
import API_URLS from '../../config/apiUrls';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

function MainBalanceWithChart({ onTotalUpdate }) {
  const [balances, setBalances] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedPayAccountType, setSelectedPayAccountType] = useState(null);
  const [availableBalance, setAvailableBalance] = useState(null);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showInnerTransferModal, setShowInnerTransferModal] = useState(false);
  const { auth, exchange } = useContext(AuthContext);
  const mainBalanceUrl = API_URLS.MAIN_BALANCE;
  const allowedCurrencies = ["USDT", "BTC", "ETH", "XRP"];

  const fetchBalances = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axios.get(mainBalanceUrl, {
        headers: { Authorization: `Bearer ${auth.token}` },
        params: { exchange },
      });
      setBalances(res.data.balance || []);
    } catch (err) {
      console.error('Error fetching main balances:', err);
      setBalances([]);
    } finally {
      setLoading(false);
    }
  }, [auth.token, exchange]);

  useEffect(() => {
    fetchBalances();
  }, [fetchBalances]);

  const totalMainValue = balances.reduce((acc, balance) => acc + (parseFloat(balance.usdtValue) || 0), 0);

  useEffect(() => {
    if (typeof onTotalUpdate === 'function') {
      onTotalUpdate(totalMainValue);
    }
  }, [totalMainValue, onTotalUpdate]);

  const handleTransferClick = (currency, freeBalance, payAccountType) => {
    setSelectedCurrency(currency);
    setAvailableBalance(freeBalance);
    setSelectedPayAccountType(payAccountType);
    setShowTransferModal(true);
  };

  const handleInnerTransferClick = (currency, freeBalance) => {
    setSelectedCurrency(currency);
    setAvailableBalance(freeBalance);
    setShowInnerTransferModal(true);
  };

  const chartData = {
    labels: balances.map(balance => balance.asset),
    datasets: [
      {
        data: balances.map(balance => parseFloat(balance.usdtValue) || 0),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
      },
    ],
  };

  const chartOptions = {
    plugins: {
      datalabels: { display: false },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.raw || 0;
            const percentage = totalMainValue > 0 ? ((value / totalMainValue) * 100).toFixed(2) + '%' : '0%';
            return `${label}: ${value.toFixed(2)} USDT (${percentage})`;
          },
        },
      },
      legend: { display: true, position: 'right' },
    },
  };

  const formatNumber = (num) => {
    if (!num) return "0"; // Handle null or undefined cases
    let formatted = parseFloat(num).toFixed(12); // Fix to 8 decimals
    formatted = formatted.replace(/\.?0+$/, ""); // Remove trailing zeros
    return formatted.replace(".", ","); // Replace dot with comma
  };
  

  return (
    <div className="balance-with-chart">
      <h4>Main: {totalMainValue.toFixed(2)} USDT</h4>

      {loading ? (
        <p>Loading...</p>
      ) : balances.length === 0 ? (
        <div className="no-balance-card">
          <a href="/markets">Transfer from Trade or Futures portfolios</a>
        </div>
      ) : (
        <div className="balance-chart-container">
          <div className="balance-table">
            <table>
              <thead>
                <tr>
                  <th>Asset</th>
                  <th>Free</th>
                  <th>Locked</th>
                  <th>Total $</th>
                  <th>Transfer</th>
                  <th>Inner Transfer</th>
                </tr>
              </thead>
              <tbody>
                {balances.map(balance => (
                  <tr key={balance.asset}>
                    <td>{balance.asset}</td>
                    <td>{formatNumber(balance.free)}</td>
                    <td>{formatNumber(balance.locked)}</td>
                    <td>${parseFloat(balance.usdtValue).toFixed(2)}</td>

                    <td>
                      {parseFloat(balance.free) > 0 && allowedCurrencies.includes(balance.asset) && (
                        <button 
                          className="transfer-btn" 
                          onClick={() => handleTransferClick(balance.asset, parseFloat(balance.free).toFixed(6), 'MAIN')}
                        >
                          <span className="transfer-text">To Futures</span>
                          <FaExchangeAlt className="transfer-icon" />
                        </button>
                      )}
                    </td>
                    <td>
                      {parseFloat(balance.free) > 0 && (
                        <button 
                          className="transfer-btn" 
                          onClick={() => handleInnerTransferClick(balance.asset, parseFloat(balance.free).toFixed(6))}
                        >
                          <span className="transfer-text">To Trade</span>
                          <FaRetweet className="transfer-icon" />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="balance-chart">
            {balances.length > 0 ? <Pie data={chartData} options={chartOptions} /> : <p>No data to display</p>}
          </div>
        </div>
      )}

      {showTransferModal && (
        <div className="modal-overlay" onClick={() => setShowTransferModal(false)}>
          <div className="modal-cont" onClick={(e) => e.stopPropagation()}>
            <TransferToFuturesForm 
              currency={selectedCurrency} 
              availableBalance={availableBalance} 
              payAccountType={selectedPayAccountType}
              onClose={() => setShowTransferModal(false)} 
            />
          </div>
        </div>
      )}

      {showInnerTransferModal && (
        <InnerTransferForm 
          currency={selectedCurrency} 
          availableBalance={availableBalance} 
          fromAccount="main"
          toAccount="trade"
          onClose={() => setShowInnerTransferModal(false)}
        />
      )}
    </div>
  );
}

export default MainBalanceWithChart;