import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import API_URLS from '../config/apiUrls';
import axios from 'axios';

const RedirectToAvailable = () => {
  const { auth, availableExchanges, exchange, setAvailableExchanges } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedExchanges = JSON.parse(sessionStorage.getItem('availableExchanges') || '[]');
  
    if (storedExchanges.length === 0 || storedExchanges === 'noExchanges') {
      axios.get(API_URLS.EXCHANGES_AVAILABILITY, {
        headers: { Authorization: `Bearer ${auth.token}` }
      }).then((response) => {
        const fetchedExchanges = response.data.exchanges;
        setAvailableExchanges(fetchedExchanges);
        sessionStorage.setItem('availableExchanges', JSON.stringify(fetchedExchanges));
      }).catch((error) => {
        console.error('Error fetching exchanges:', error);
        setAvailableExchanges([]);
      });
    } else {
      setAvailableExchanges(storedExchanges);
    }
  
    setLoading(false);
  }, [auth.token, setAvailableExchanges]);
  
  

  if (loading) {
    return <p>Loading...</p>; // Prevents unnecessary redirects before data is fetched
  }

  const isAuthenticated = auth.token !== null;
  const storedExchange = localStorage.getItem('exchange') || '';

  // Not authenticated → redirect to login
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Authenticated but no exchanges → redirect to update-exchange
  if (!storedExchange || availableExchanges.length === 0 || availableExchanges === 'noExchanges') {
    return <Navigate to="/update-exchange" />;
  }

  // Authenticated with exchanges → try routes in order
  const preferredRoutes = [
    '/dashboard',
    '/markets',
    '/balance',
    '/trading-platform',
    '/list-bots'
  ];

  // Find the first available route
  const firstAvailableRoute = preferredRoutes.find(route => 
    availableExchanges.includes(route.replace('/', ''))
  );

  // Redirect to first available route, or fallback to /update-exchange
  return <Navigate to={firstAvailableRoute || '/dashboard'} />;
};

export default RedirectToAvailable;
