import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { getBinanceTickerPrice, getKucoinTickerPrice } from '../../utils/exchangeAPI';
import './ExchangeTickerInfo.css'

function ExchangeTickerInfo() {
    const { exchange, rawPair } = useContext(AuthContext);
    const [tickerInfo, setTickerInfo] = useState(null);
  
    useEffect(() => {
      const fetchTickerPrice = async () => {
        try {
          let data;
          if (exchange === 'binance') {
            data = await getBinanceTickerPrice(rawPair);
          } else if (exchange === 'kucoin') {
            data = await getKucoinTickerPrice(rawPair);
          }
  
          setTickerInfo(data);
        } catch (error) {
          console.error('Error fetching ticker price:', error);
        }
      };
  
      // Call the function immediately to fetch the initial data
      fetchTickerPrice();

      // Set up an interval to fetch the data every 30 seconds
      const intervalId = setInterval(fetchTickerPrice, 30000); // 30000 milliseconds = 30 seconds

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }, [exchange, rawPair]);
  
    // Adjusted the way price is accessed based on the exchange
    const getPrice = () => {
      if (exchange === 'binance') {
        return {
          lastPrice: tickerInfo.lastPrice,
          changePercent: tickerInfo.priceChangePercent,
      };
      } else if (exchange === 'kucoin' && tickerInfo.data) {
        return tickerInfo.data.price;
      }
      return null;
    }
  
    return (
      <div className="ticker-info-container">
        {tickerInfo && exchange === 'binance'? (
          <div>
            <p>
              Price: {getPrice().lastPrice}{' '}
              <small style={{ color: getPrice().changePercent < 0 ? 'red' : 'green' }}>
                ({getPrice().changePercent < 0 ? '-' : '+'}{Math.abs(getPrice().changePercent)}%)
              </small>
            </p>
          </div>
      
        ) : tickerInfo && exchange === 'kucoin' ? (
          <div>
            <p>Price: {getPrice()}</p>
          </div>
        ) : (
          <p>Loading ticker info...</p>
        )}
      </div>
    );
}
  
export default ExchangeTickerInfo;
